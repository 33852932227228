import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const PredictiveAi = () => {

    return (
        <Layout>
            <SEO title="Predictive and AI Technology" />

            <div className="content-wrapper">
                <div className="page-intro pb-4">
                    <h1 className="mb-3 h2">Predictive and AI Technology</h1>
                </div>
            </div>
            <div className="container">
                <div className="predictive-rose mb-4 pb-4">
                    <div>
                        <h4>The future is looking Rose-y</h4>
                        <p className="mb-4">At Restorativ we recognize that our technology deals with some very emotional and complicated situations. A pure AI solution that removes the human touch in mentoring, mediation and restorative practice will not work today. Just like self-driving mode allows cars to drive autonomously, our AI platform will recommend and suggest restorative approaches but allow case facilitators to keep their hands close to the driving wheel ready to take control.
                        </p>
                        <p className="mb-4">Our Restorative Outcome Suggestion Engine (ROSE) is a Generative AI assistant that will be ready and on call 24 hours to support case facilitators. ROSE can help answer questions / issues / problems quickly and always with a restorative eye.
                        </p>
                    </div>
                    <div>
                        <StaticImage src="../../images/ai/ai.png" alt='Ai ' />
                    </div>
                </div>

            </div>
            <div className="section-primary">
                <div className="container pt-4">


                    <div className="predictive-persona mb-4 pb-4">
                        <div className="text-center mb-3 mb-md-0">
                            <StaticImage width={300} src="../../images/ai/persona-phone.png" alt='Persona phone ' />
                        </div>
                        <div>
                            <h4>Predicting Empathy to get better results</h4>
                            <p className="mb-4">Restorativ has embedded a dynamic predictive empathy assessment in order to help both facilitators and those participating in mentoring, mediation and restorative practice better understand their own empathy type. We utilize a proven assessment that is taken during the early part of case work to help all involved understand what Empathy Types work best together and those that don't. We group Empathy Types as “trees” for quicker visual recognition.</p>
                            <StaticImage width={700} src="../../images/ai/personas.png" alt='Personas ' />
                        </div>
                    </div>
                </div>

            </div>
        </Layout >
    )
}
export default PredictiveAi;